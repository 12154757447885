export const books = [
    { 
      id: 1,
      author: "Hunter S. Tompson",
      title: "Fear hand Loathing in Las Vegas",
      img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.2I_ZJy-5ohMa6E8LnB-LhAHaMk%26pid%3DApi&f=1"
    },
    {
      id: 2,
      author: "James Fadiman",
      title: "The Psychedelic Explorer's Guide",
      img: "https://images-na.ssl-images-amazon.com/images/I/51to85WlrxL._SX332_BO1,204,203,200_.jpg"
    }
  ]