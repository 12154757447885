import React from 'react';
import ReactDom from 'react-dom';

import './index.css'
import { books } from './books'
import Book from './Book'

function BookList(){
  return ( 
    <section className="booklist"> 
      {books.map((book) => {
        return (
          <Book key={book.id} {...book}></Book>
        )
      })} 
    </section>
  )
}

// class BookList extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       error: null,
//       isLoaded: false,
//       items: []
//     };
//   }
  
//   componentDidMount() {
//     fetch("http://localhost:4000/books")
//       .then(res => res.json())
//       .then (
//         (result) => {
//           this.setState({
//             isLoaded: true,
//             items: result.books
//           })
//         },
//         (error) => {
//           this.setState({
//             isLoaded: true,
//             error
//           });
//         }
//       )
//   }

//   render() {
//     const { error, isLoaded, items } = this.state;
//     if (error) {
//       return <div>Error: {error.message}</div>;
//     } else if (!isLoaded) {
//       return <div>Loading...</div>;
//     } else {
//       return (
//         <section className="booklist"> 
//           {items.map((book) => {
//             return (
//               <Book key={book.id} {...book}></Book>
//             )
//           })} 
//         </section>
//       )
//     }
//   }
// }
    
ReactDom.render(<BookList/>, document.getElementById('root'))
